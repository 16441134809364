import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import Asset from 'src/interfaces/Asset';
import Utterance from 'src/interfaces/UtteranceWithId';
import { Overlay } from 'shared-models';
import { useAuth } from 'src/contexts/authProvider';

type EditorConfigContextType = {
  active: boolean;
  activateEditor: () => void;
  deactivateEditor: () => void;
  overlays: Overlay[];
  resetConfig: () => void;
  selectedAssets: Asset[];
  setSelectedAssets: (assets: Asset[]) => void;
  selectedUtterances: Utterance[];
  setSelectedUtterances: (utterances: Utterance[]) => void;
  toggleUtterance: (utterance: Utterance) => void;
};

const initialContext: EditorConfigContextType = {
  active: false,
  activateEditor: () => {},
  deactivateEditor: () => {},
  overlays: [],
  resetConfig: () => {},
  selectedAssets: [],
  setSelectedAssets: () => {},
  selectedUtterances: [],
  setSelectedUtterances: () => {},
  toggleUtterance: () => {},
};

const EditorConfigContext =
  createContext<EditorConfigContextType>(initialContext);

type EditorConfigProviderProps = {
  children: ReactNode;
};

export const EditorConfigProvider: React.FC<EditorConfigProviderProps> = ({
  children,
}) => {
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]),
    [selectedUtterances, setSelectedUtterances] = useState<Utterance[]>([]),
    [overlays, setOverlays] = useState<Overlay[]>([]),
    [active, setActive] = useState(false),
    { machineApi, user } = useAuth();

  const resetConfig = () => {
      setSelectedAssets([]);
      setSelectedUtterances([]);
    },
    activateEditor = () => {
      console.log('Activating editor');
      setActive(true);
    },
    deactivateEditor = () => {
      console.log('Deactivating editor');
      setActive(false);
    },
    toggleUtterance = (utterance: Utterance) => {
      if (selectedUtterances.find((u) => u.id === utterance.id)) {
        setSelectedUtterances(
          selectedUtterances.filter((u) => u.id !== utterance.id)
        );
      } else {
        setSelectedUtterances([...selectedUtterances, utterance]);
      }
    },
    value: EditorConfigContextType = {
      active,
      activateEditor,
      deactivateEditor,
      overlays,
      resetConfig,
      selectedAssets,
      setSelectedAssets,
      selectedUtterances,
      setSelectedUtterances,
      toggleUtterance,
    };

  // Load the overlays from the API
  useEffect(() => {
    machineApi.fetchOverlays().then((data) => {
      setOverlays(data);
    });
  }, [user]);

  return (
    <EditorConfigContext.Provider value={value}>
      {children}
    </EditorConfigContext.Provider>
  );
};

export const useEditorConfigContext = (): EditorConfigContextType => {
  const context = useContext(EditorConfigContext);
  if (context === undefined) {
    throw new Error(
      'useEditorConfigContext must be used within an EditorConfigProvider'
    );
  }
  return context;
};
