import TermsAcknowledgement from '@/components/TermsAcknowledgement';
import pluralize from 'pluralize';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEditorConfigContext } from 'src/contexts/editorConfigProvider';
import DEFAULTS from 'src/defaults';
import FLAGS from 'src/featureFlags';
import Asset from 'src/interfaces/Asset';
import Params from 'src/interfaces/AssetLibraryParams';
import Analytics from 'src/utils/analytics';
import EventBus from 'src/utils/eventBus';
import { useAuth } from '../../contexts/authProvider';
import { getVideoMetadata, videoMetaDataDb } from '../../utils/videoMetadataDb';
import AdvancedSettings from './components/AdvancedSettings';
import EditDialog from './components/edit-dialog';
import Filters from './components/Filters';
import Loading from './components/Loading';
import Modal from './components/Modal';
import AssetLibraryParams from 'src/interfaces/AssetLibraryParams';
import Trending from './components/Trending';
import VideoList from './components/VideoList';

const Root: React.FC = () => {
  /**
   *
   * Assets are tracked in three states:
   * - assetIds is an array of all pinecone asset IDs returned by the keyword
   * - assets is an object with asset IDs as keys and optional metadata as values
   * - filteredAssets is an array of assets with optional metadata
   */

  const defaultParams: AssetLibraryParams = {
      topics: [],
      vibes: [],
      speakers: [],
      brs: 0,
      sort: 'rel',
    },
    idMatcher = /[a-f0-9]{64}/,
    navigate = useNavigate(),
    location = useLocation(),
    { searchQuery: searchQueryInRoute } = useParams(),
    isSearchPath = searchQueryInRoute !== undefined,
    [assetIds, setAssetIds] = useState<string[]>([]),
    [isSingleId, setIsSingleId] = useState<boolean>(
      idMatcher.test(searchQueryInRoute || '')
    ),
    [assets, setAssets] = useState<{ [key: string]: Asset }>({}),
    [filteredAssets, setFilteredAssets] = useState<Asset[]>([]),
    [metadataLoaded, setMetadataLoaded] = useState(false),
    [params, setParams] = useState<Params>(defaultParams),
    [percentLoaded, setPercentLoaded] = useState(0),
    [searchQuery, setSearchQuery] = useState(searchQueryInRoute || ''),
    [lastSearchQuery, setLastSearchQuery] = useState(''),
    [localSearchType, setLocalSearchType] = useState<string>(
      DEFAULTS.search.type
    ),
    [localK, setLocalK] = useState<number>(DEFAULTS.search.localK),
    [loading, setLoading] = useState(false),
    [settingsOpen, setSettings] = useState(false),
    [globallyExpanded, setGlobalExpanded] = useState(false),
    { machineApi } = useAuth();

  const editorConfig = useEditorConfigContext(),
    handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      navigate(`/search/${searchQuery}`);
    },
    doSearch = async () => {
      console.log('Form submitted');
      const theSingleVideo = { id: searchQuery };
      const isSingle = idMatcher.test(searchQuery);
      setIsSingleId(isSingle); // we need this outside this function as well

      setLoading(true);
      setAssetIds([]);
      setAssets({});
      setLastSearchQuery(searchQuery);
      setMetadataLoaded(false);
      setParams(defaultParams);
      setPercentLoaded(0);
      editorConfig.resetConfig();
      if (loading) return;

      try {
        let results = null;
        if (isSingle) {
          results = [theSingleVideo];
        } else {
          results = await machineApi.fetchVideoIds({
            searchQuery,
            localSearchType,
            localK,
          });
        }
        setLoading(false);
        setAssetIds(results.map((asset) => asset.id));
        setAssets(
          results.reduce(
            (acc, asset) => {
              acc[asset.id] = { id: asset.id };
              return acc;
            },
            {} as { [key: string]: Asset }
          )
        );
        Promise.all(
          results.map((asset, index) =>
            getVideoMetadata(asset.id, index).then((metadata: Asset) => {
              setAssets((prev) => ({ ...prev, [asset.id]: metadata }));
            })
          )
        ).then(() => {
          setMetadataLoaded(true);
          if (results.length === 1) {
            setGlobalExpanded(true);
          }
        });

        if (results.length > 0) {
          Analytics.log('search', { searchQuery, localSearchType, localK });
        } else {
          Analytics.log('search_error', { searchQuery, error: 'No results' });
        }
      } catch (err) {
        Analytics.log('search_error', { searchQuery, error: err });
        setLoading(false);
        setAssetIds([]);
      }
    },
    handleSettings = (evt: React.MouseEvent) => {
      evt.preventDefault();
      setSettings(!settingsOpen);
    };

  // Update percent loaded for use in filter component
  useEffect(() => {
    if (assetIds.length === 0) {
      setPercentLoaded(0);
      return;
    }
    const metaLoaded = Object.values(assets).filter(
      (obj) => obj.annotations
    ).length;
    setPercentLoaded(Math.round((metaLoaded / assetIds.length) * 100));
  }, [assets]);

  // The actual filtering of assets is performed here
  useEffect(() => {
    // If there is no search query, we return early, to prevent
    // the entire metadataDb from being shown when switching back
    // to this component after a search
    if (assetIds.length === 0) {
      return;
    }
    let query = [{ id: { $in: assetIds } }] as any;
    const opts = {} as any;

    if (params.brs !== 0) {
      query.push({
        score_data: { percentiles: { everyone: { $gte: params.brs } } },
      });
    }
    if (params.topics.length > 0) {
      query.push({
        annotations: {
          topics: { $in: params.topics.map((topic) => new RegExp(topic, 'i')) },
        },
      });
    }
    if (params.vibes.length > 0) {
      query.push({
        annotations: {
          vibes: { $in: params.vibes.map((vibe) => new RegExp(vibe, 'i')) },
        },
      });
    }
    if (params.speakers.length > 0) {
      query.push({
        annotations: {
          speakers: {
            $in: params.speakers.map((speaker) => new RegExp(speaker, 'i')),
          },
        },
      });
    }

    switch (params.sort) {
      case 'brs':
        opts.$orderBy = { score_data: { percentiles: { everyone: -1 } } };
        break;
      case 'date':
        opts.$orderBy = { date_added: -1 };
        break;
      default:
        opts.$orderBy = { relevance: 1 };
        break;
    }
    query = { $and: query };
    const result = videoMetaDataDb.find(query, opts);
    console.log('Ran forerunner query: ', query, opts);
    setFilteredAssets(result);
  }, [params, assets]);

  let statusMessage = <></>;
  if (filteredAssets.length > 0) {
    if (isSingleId) {
      statusMessage = (
        <>
          Video&nbsp;
          <code className="overflow-ellipsis overflow-hidden">
            {lastSearchQuery}
          </code>
        </>
      );
    } else {
      statusMessage = (
        <>{`${pluralize('result', filteredAssets.length, true)} for "${lastSearchQuery}" found.`}</>
      );
    }
  }

  const updateParams = useCallback(
    (key: string, value: string[] | number) => {
      const newParams = { ...params, [key]: value };
      setParams(newParams);
    },
    [params]
  );

  useEffect(() => {
    EventBus.$on('params:topics', (value: string[]) => {
      updateParams('topics', value);
    });
    EventBus.$on('params:vibes', (value: string[]) => {
      updateParams('vibes', value);
    });
    EventBus.$on('params:speakers', (value: string[]) =>
      updateParams('speakers', value)
    );
    EventBus.$on('params:brs', (value: number) => updateParams('brs', value));
    EventBus.$on('params:clear', () => setParams(defaultParams));
    return () => {
      EventBus.$off('params:topics');
      EventBus.$off('params:vibes');
      EventBus.$off('params:speakers');
      EventBus.$off('params:brs');
      EventBus.$off('params:clear');
    };
  }, [params]);

  // Lastly, handle the fetch if this is a search path
  useEffect(() => {
    if (isSearchPath) {
      doSearch();
    }
  }, [location]);

  return (
    <div>
      <Modal />

      {editorConfig.active && <EditDialog />}

      {!editorConfig.active && (
        <>
          {/* Search */}
          <p className="text-neutral-190 text-[22px] mb-6 font-source_serif_pro">
            Search our video database for the clips you need
          </p>

          <AdvancedSettings
            localK={localK}
            setK={setLocalK}
            searchType={localSearchType}
            setSearchType={setLocalSearchType}
            open={settingsOpen}
          />

          <div className="block relative mb-16">
            <form onSubmit={handleSubmit}>
              <input
                type="search"
                name="query"
                value={searchQuery}
                onChange={(evt) => setSearchQuery(evt.target.value)}
                className="block w-full rounded-[10px] border border-neutral-190 appearance-auto hover:appearance-none py-6 pl-16 pr-20 bg-white outline-0 ring-0 text-[22px] leading-none font-inter text-neutral-190 placeholder:text-neutral-190"
                placeholder="Search a keyword to find relevant clips"
              />
              <button
                type="button"
                onClick={handleSettings}
                className="block absolute top-1/2 left-4 -translate-y-1/2 w-8 h-8 bg-ico-search bg-no-repeat bg-center"
              ></button>
              <button
                type="submit"
                className="transition-colors block absolute top-1.5 right-1.5 w-[72px] h-16 rounded-[10px] bg-green-100 bg-ico-arrow-right bg-center bg-no-repeat hover:bg-green-120"
              ></button>
            </form>
          </div>
          {/* //Search */}

          {FLAGS.ASSET_LIBRARY.TRENDING && <Trending />}

          {assetIds.length > 0 && (
            <Filters
              assets={assets}
              filteredAssets={filteredAssets}
              metadataLoaded={metadataLoaded}
              percentLoaded={percentLoaded}
              params={params}
              setParams={setParams}
              statusMessage={statusMessage}
              showAnnotations={globallyExpanded}
              setShowAnnotations={setGlobalExpanded}
            />
          )}

          {/* Results */}
          <div className="flex flex-wrap gap-3">
            {loading && <Loading />}

            {!loading && (
              <VideoList
                assets={filteredAssets}
                forceExpanded={globallyExpanded}
              />
            )}
          </div>
          {/* //Results */}

          {/* How to get started */}
          {assetIds.length == 0 && (
            <div className="block">
              <p className="text-neutral-190 text-[22px] mb-6 font-source_serif_pro">
                How to get started:
              </p>

              <div className="grid grid-cols-3 gap-x-5">
                <div className="rounded bg-neutral-80 px-7 py-12">
                  <span className="block font-inter text-lg text-neutral-180 mb-3">
                    1. Search
                  </span>
                  <p className="h-[120px] mb-[20px] block font-inter text-sm text-neutral-180 pl-[20px]">
                    Find relevant clips using key words in the search bar.
                  </p>
                  <div className="block w-full pb-[21.96%] bg-center bg-no-repeat bg-cover bg-how-to-search"></div>
                </div>

                <div className="rounded bg-neutral-80 px-7 py-12">
                  <span className="block font-inter text-lg text-neutral-180 mb-3">
                    2. Select clips & edit
                  </span>
                  <p className="h-[120px] mb-[20px] block font-inter text-sm text-neutral-180 pl-[20px]">
                    Choose the parts of the video you want to keep. You can edit
                    it by adding captions, face tracking, and adjusting the
                    video scaling.
                  </p>
                  <div className="block w-full pb-[155.50%] bg-center bg-no-repeat bg-cover bg-how-to-select"></div>
                </div>

                <div className="rounded bg-neutral-80 px-7 py-12">
                  <span className="block font-inter text-lg text-neutral-180 mb-3">
                    3. Download
                  </span>
                  <p className="h-[120px] mb-[20px] block font-inter text-sm text-neutral-180 pl-[20px]">
                    View and download your video in the notifications page.
                  </p>
                  <div className="block w-full pb-[53.90%] bg-center bg-no-repeat bg-cover bg-how-to-download"></div>
                </div>
              </div>
            </div>
          )}
          {/* How to get started */}
        </>
      )}
      <TermsAcknowledgement />
    </div>
  );
};

export default Root;
