import { User } from 'firebase/auth';
import { EditingConfig, Overlay } from 'shared-models';
import Asset from 'src/interfaces/Asset';
import Utterance from 'src/interfaces/UtteranceWithId';
import FLAGS from 'src/featureFlags';
import { FIRESTORE_COLLECTION_NAMES } from 'src/defaults';
import { init } from '@paralleldrive/cuid2';
import { doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { API_URL } from './api';

export interface PineconeAsset {
  id: string;
}

export interface VideoIdSearchParams {
  searchQuery: string;
  localSearchType: string;
  localK: number;
}

interface submitEditRequestParams {
  assets: Asset[];
  selectedUtterances: Utterance[];
  config: EditingConfig;
  onFailure: () => void;
  onSuccess: () => void;
}

export default class MachineApi {
  user: User | null;

  constructor(user: User | null) {
    this.user = user;
  }

  async fetchVideoIds(params: VideoIdSearchParams): Promise<PineconeAsset[]> {
    if (this.user === null) {
      return [];
    }
    try {
      const idToken = await this.user.getIdToken();

      const url = new URL(`${API_URL}/video_ids`);
      url.searchParams.append('query', params.searchQuery);
      url.searchParams.append('search_type', params.localSearchType);
      url.searchParams.append('k', params.localK.toString());
      url.searchParams.append(
        'asset_library',
        FIRESTORE_COLLECTION_NAMES.ASSETS
      );

      const response = await fetch(url.toString(), {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      const data: { results: PineconeAsset[] } = await response.json();
      if (!('results' in data)) {
        console.error('Error: invalid API response');
        return [];
      }

      console.log(data);
      // logEvent(analytics, 'search', {
      //   search_term: searchQuery,
      //   search_type: localSearchType,
      //   results_count: data.results.length,
      //   user_id: user.uid,
      // });

      return data.results;
    } catch (error) {
      console.error('Error fetching videos:', error);
      // logEvent(analytics, 'search_error', {
      //   error_message:
      //     error instanceof Error ? error.message : 'Unknown error',
      //   user_id: user.uid,
      // });
      return [];
    }
  }

  async fetchOverlays(): Promise<Overlay[]> {
    if (this.user === null) {
      return [];
    }
    try {
      const idToken = await this.user.getIdToken();
      const response = await fetch(`${API_URL}/overlays`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch overlays');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching overlays:', error);
      return [];
    }
  }

  async submitEditRequest(params: submitEditRequestParams) {
    if (FLAGS.ASSET_LIBRARY.MULTICLIP_EDITS) {
      throw new Error('Multiclip edits are not supported');
    }
    const { assets, selectedUtterances, config, onSuccess, onFailure } = params,
      asset = assets[0];

    if (!asset.transcription) {
      console.error('No transcription available for this asset');
      onFailure();
      return;
    }

    if (!this.user) {
      alert('You must be logged in to submit an asset.');
      onFailure();
      return;
    }

    try {
      const idToken = await this.user.getIdToken(),
        cuid = init({ length: 21 }),
        editRequestId = cuid();

      const derefUtterances = selectedUtterances.map((u) => {
        const { id, ...rest } = u;
        return rest;
      });

      const data = {
        id: editRequestId,
        asset_id: asset.id,
        selected_utterances: derefUtterances,
        editing_config: config,
      };

      const editRequestData = {
        id: editRequestId,
        assetId: asset.id,
        userId: this.user.uid,
        status: 'pending',
        createdAt: Timestamp.now(),
        selectedUtterances,
        editingConfig: config,
        read: false,
      };

      const editRequestRef = doc(
        db,
        FIRESTORE_COLLECTION_NAMES.EDIT_REQUESTS,
        editRequestId
      );
      await setDoc(editRequestRef, editRequestData);
      console.log('Document written with ID: ', editRequestRef.id);

      const response = await fetch(`${API_URL}/edit_video`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to submit video editing job');
      }

      await updateDoc(editRequestRef, { status: 'submitted' });
      console.log('Submitting edit request to firestore:', editRequestData);

      const result = await response.json();
      console.log('Job submitted successfully:', result);
      console.log('Edit request created with Document ID: ', editRequestId);
    } catch (error) {
      console.error('Error submitting video editing job:', error);
      alert('Failed to submit video editing job. Please try again.');
    }
    onSuccess();
  }
}
