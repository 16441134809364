import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import pluralize from 'pluralize';
import cx from 'classnames';
import Asset from 'src/interfaces/Asset';

import { VideoCardSuspense } from './VideoCardSuspense';
import Video from './Video';
import BlueRoseGauge from './BlueRoseGauge';
import Transcript from './Transcript';
import TranscriptHighlights from './TranscriptHighlights';
import Vibes from './Vibes';
import FLAGS from 'src/featureFlags';
import truncateText from 'src/utils/truncateText';
import { useEditorConfigContext } from 'src/contexts/editorConfigProvider';
import { API_URL } from 'src/utils/api';
import Analytics from 'src/utils/analytics';
import AnnotationsTab from './AnnotationsTab';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// import Slider from 'react-slick';

export interface VideoCardProps {
  assetId: string;
  metadata: Asset;
  forceExpanded?: boolean;
}

const MemoizedVideo = memo(Video);

const VideoCard: React.FC<VideoCardProps> = (props: VideoCardProps) => {
  const [localIsExpanded, setLocallocalIsExpanded] = useState(false),
    [isVisible, setIsVisible] = useState(false),
    [activeTab, setActiveTab] = useState('transcript'),
    [searchQuery, setSearchQuery] = useState(''),
    [linkCopied, setLinkCopied] = useState<boolean>(false),
    [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null),
    observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

  // Refs for handling the height of the expanded card.
  const [videoCardRef, setVideoCardRef] = useState<HTMLDivElement | null>(null),
    videoExpandedHeaderRef = useRef<HTMLDivElement | null>(null),
    videoExpandedFooterRef = useRef<HTMLDivElement | null>(null),
    [transcriptsContainerHeight, setTranscriptsContainerHeight] = useState(0),
    checkVideoCardRef = useCallback((node: HTMLDivElement | null) => {
      if (node) {
        setVideoCardRef(node);
      } else {
        setVideoCardRef(null);
      }
    }, []);

  // IntersectionObserver to lazy-init the video elements
  useEffect(() => {
    if (videoCardRef) {
      observer.observe(videoCardRef);
    }
    return () => observer.disconnect();
  }, [videoCardRef]);

  useEffect(() => {
    if (linkCopied) {
      setTimeout(() => {
        setLinkCopied(false);
      }, 3000);
    }
  }, [linkCopied]);

  const handleExpanded = () => {
      setLocallocalIsExpanded(!localIsExpanded);
      if (!localIsExpanded && videoCardRef) {
        Analytics.log('search_videocard_description_click', {
          assetId: props.assetId,
        });
        setTimeout(() => {
          videoCardRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 100);
      }
    },
    // function consumed by the transcript component to jump to clips
    jumpToClip = useCallback(
      (start_time: number) => {
        if (videoElement) {
          videoElement.currentTime = start_time;
          videoElement.play();
        }
      },
      [videoElement]
    ),
    // editorConfig context to access the number of selected utterances
    {
      active: editorIsActive,
      activateEditor,
      setSelectedAssets,
      selectedUtterances,
    } = useEditorConfigContext(),
    startEditing = (event: string) => {
      if (FLAGS.ASSET_LIBRARY.MULTICLIP_EDITS) {
        throw new Error('Multiclip edits not supported');
      }
      if (event) {
        Analytics.log(event, { assetId: props.assetId });
      }
      if (props.metadata) {
        setSelectedAssets([props.metadata]);
        activateEditor();
      }
    },
    // composite prop to determine if the card is expanded
    isExpanded = props.forceExpanded || localIsExpanded;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // const sliderSettings = {
  //   dots: false,
  //   arrows: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };

  useLayoutEffect(() => {
    const updateTranscriptionHeight = () => {
      if (
        videoCardRef &&
        videoExpandedHeaderRef.current &&
        videoExpandedFooterRef.current
      ) {
        setTimeout(() => {
          if (
            videoExpandedFooterRef.current &&
            videoExpandedHeaderRef.current
          ) {
            const expandedHeaderAndFooterHeight =
              videoExpandedHeaderRef.current.offsetHeight +
              videoExpandedFooterRef.current.offsetHeight +
              58;
            const transcriptsHeight = videoCardRef.offsetHeight;
            setTranscriptsContainerHeight(
              transcriptsHeight - expandedHeaderAndFooterHeight
            );
          }
        }, 50);
      }
    };

    if (isExpanded) {
      updateTranscriptionHeight();
    }
  }, [
    props.forceExpanded,
    localIsExpanded,
    videoCardRef,
    videoExpandedHeaderRef,
    videoExpandedFooterRef,
  ]);

  // Human-format date
  let enteredDate = '';
  if (props.metadata.date_added) {
    // Date format ex: Aug 1, 2024
    enteredDate = new Date(props.metadata.date_added * 1000).toLocaleDateString(
      'en-US',
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }
    );
  }

  // Build the transcript component
  let transcript = null;
  if (props.metadata?.transcription) {
    transcript = (
      <Transcript
        searchQuery={searchQuery}
        utterances={props.metadata.transcription}
        jumpToClip={jumpToClip}
      />
    );
  }

  return (
    <>
      {!props.metadata.annotations && <VideoCardSuspense />}

      {props.metadata.annotations && (
        <div
          ref={checkVideoCardRef}
          className={`bg-white border border-[#CDCDCD] rounded-[10px] overflow-hidden relative group ${isExpanded ? 'w-full' : 'w-[calc(50%-6px)]'}`}
        >
          <div className="flex">
            <div className={`${isExpanded ? 'w-[calc(50%-6px)]' : 'w-full'}`}>
              <div className="block w-full h-0 relative pb-[56.25%] overflow-hidden">
                {!editorIsActive && (
                  <div className="invisible absolute right-2 top-2 flex gap-x-2 z-40 group-hover:visible">
                    <CopyToClipboard
                      text={`${window.location.origin}/search/${props.assetId}`}
                      onCopy={() => setLinkCopied(true)}
                    >
                      <button className=" font-inter text-xs text-neutral-190 cursor-pointer transition-colors flex items-center gap-x-2 py-2 px-3 bg-white rounded-md hover:bg-neutral-80">
                        {linkCopied ? (
                          <i className="block w-4 h-4 bg-ico-check bg-center bg-no-repeat"></i>
                        ) : (
                          <i className="block w-4 h-4 bg-ico-link bg-center bg-no-repeat"></i>
                        )}
                      </button>
                    </CopyToClipboard>
                    <a
                      href={`${API_URL}/video/assets/${encodeURIComponent(props.assetId)}`}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cursor-pointer transition-colors flex items-center gap-x-2 py-2 px-3 bg-white rounded-md hover:bg-neutral-80"
                      onClick={() =>
                        Analytics.log('search_videocard_download', {
                          assetId: props.assetId,
                        })
                      }
                    >
                      <span className="font-inter text-xs text-neutral-190">
                        Download
                      </span>
                      <i className="block w-4 h-4 bg-ico-download bg-center bg-no-repeat"></i>
                    </a>
                    <button
                      type="button"
                      onClick={startEditing.bind(
                        this,
                        'search_videocard_edit_initiation'
                      )}
                      className="transition-colors flex items-center gap-x-2 py-2 px-3 bg-white rounded-md hover:bg-neutral-80"
                    >
                      <span className="font-inter text-xs text-neutral-190">
                        Edit
                      </span>
                      <i className="block w-4 h-4 bg-ico-edit bg-center bg-no-repeat"></i>
                    </button>
                  </div>
                )}

                {isVisible && (
                  <MemoizedVideo
                    ref={(node) => {
                      setVideoElement(node);
                    }}
                    className="absolute top-0 left-0 w-full h-full"
                    assetId={props.assetId}
                  />
                )}
              </div>
              <div className="block p-5">
                <div>
                  <span
                    className="block font-inter text-[10px] leading-[14px] text-neutral-170 mb-1"
                    title={`AssetID ${props.assetId} - click to log to console`}
                    onClick={() => console.log(props.assetId)}
                  >
                    Added {enteredDate}
                  </span>
                  <span
                    onClick={handleExpanded}
                    className="cursor-pointer block font-source_serif_pro text-base text-[#151515] mb-4"
                    title={props.metadata.annotations?.description}
                  >
                    {truncateText(props.metadata.annotations?.description, 350)}
                  </span>

                  {FLAGS.ASSET_LIBRARY.TRANSCRIPT_HIGHLIGHTS && (
                    <TranscriptHighlights />
                  )}

                  {props.metadata.annotations && (
                    <Vibes
                      vibes={props.metadata.annotations.vibes}
                      topics={props.metadata.annotations?.topics}
                    />
                  )}

                  <BlueRoseGauge score_data={props.metadata.score_data} />
                </div>

                <div className="w-full flex items-center justify-between mt-4">
                  <div className="no-shrink">
                    <div
                      onClick={handleExpanded}
                      className="flex items-center gap-x-3 cursor-pointer text-neutral-170 hover:text-green-100"
                    >
                      <span>Show annotations</span>
                      <button
                        className={`w-10 h-5 rounded-full flex items-center transition duration-300 focus:outline-none ${isExpanded ? 'bg-green-100' : 'bg-neutral-120'}`}
                      >
                        <div
                          className={`w-4 h-4 relative rounded-full transition duration-500 transform bg-white ${isExpanded ? 'translate-x-[22px]' : 'translate-x-0.5'}`}
                        ></div>
                      </button>
                    </div>
                  </div>
                  {FLAGS.ASSET_LIBRARY.MULTICLIP_CARDS && (
                    <div className="flex items-center gap-x-2">
                      <div className="font-inter text-[10px] leading-[14px] text-neutral-170">
                        <div className="flex items-center gap-x-2">
                          <span>1 of 3</span>
                          <i className="block w-[13px] h-[13px] bg-center bg-contain bg-no-repeat bg-ico-info"></i>
                        </div>
                      </div>
                      <div>[slider buttons goes here]</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${isExpanded ? 'block w-[calc(50%+6px)]' : 'hidden'}`}
            >
              <div className="flex flex-col h-full">
                <div className="flex shrink-0 border-b border-neutral-110 bg-neutral-80">
                  <div className="-mt-[1px] w-1/2 overflow-hidden">
                    <span
                      onClick={() => setActiveTab('transcript')}
                      className={`block rounded-tr-[8px] transition-colors h-full w-full pb-3 pt-5 font-inter text-base text-neutral-160 text-center cursor-pointer ${activeTab === 'transcript' ? 'text-neutral-190 bg-neutral-20 border-t border-r border-neutral-110' : ''}`}
                    >
                      Transcript
                    </span>
                  </div>
                  <div className="-mt-[1px] w-1/2 overflow-hidden">
                    <span
                      onClick={() => setActiveTab('annotations')}
                      className={`block rounded-tl-[8px] transition-colors h-full w-full pb-3 pt-5 font-inter text-base text-neutral-160 text-center cursor-pointer ${activeTab === 'annotations' ? 'text-neutral-190 bg-neutral-20 border-t border-l border-neutral-110' : ''}`}
                    >
                      Annotations
                    </span>
                  </div>
                </div>
                <div className="flex h-full grow border-l border-neutral-110">
                  {activeTab === 'transcript' && (
                    <div className="block">
                      <div
                        ref={videoExpandedHeaderRef}
                        className="block pt-7 px-5 pb-5"
                      >
                        <p className="font-source_serif_pro text-base text-[#151515]">
                          Select a clip by clicking its checkbox. Once selected,
                          clips can be downloaded as snippets or edited together
                          into a new cut.
                        </p>
                        {FLAGS.ASSET_LIBRARY.SEARCH_TRANSCRIPT && (
                          <input
                            className="mt-5 w-full rounded-md border border-neutral-110 py-2 px-4 font-inter text-sm text-neutral-140 outline-0 ring-0 focus:border-green-100"
                            type="search"
                            placeholder="Search transcript"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          height: `${transcriptsContainerHeight}px`,
                        }}
                        className="block border-t border-neutral-110"
                      >
                        <div
                          style={{
                            height: `${transcriptsContainerHeight}px`,
                          }}
                          className={`scrollbar scrollbar-track-neutral-100 scrollbar-thumb-neutral-160/20 overflow-y-scroll`}
                        >
                          {transcript}
                        </div>
                      </div>
                      <div
                        ref={videoExpandedFooterRef}
                        className="flex justify-between items-center p-5 bg-neutral-100 border-t border-neutral-110"
                      >
                        {selectedUtterances.length > 0 && (
                          <span className="font-inter text-xs text-neutral-190">
                            {pluralize('clip', selectedUtterances.length, true)}{' '}
                            selected
                          </span>
                        )}
                        <div className="flex gap-x-2">
                          {false && (
                            <button
                              className={cx(
                                { invisible: selectedUtterances.length === 0 },
                                'transition-colors flex justify-between items-center gap-x-3 rounded-md bg-neutral-180 py-2 px-3 hover:bg-neutral-180/80'
                              )}
                            >
                              <span className="font-inter text-xs text-white">
                                Download selections
                              </span>{' '}
                              <i className="block w-4 h-4 bg-ico-download-white bg-center bg-no-repeat bg-contain"></i>
                            </button>
                          )}
                          <button
                            onClick={startEditing.bind(
                              this,
                              'search_videocard_edit_initiation'
                            )}
                            className={cx(
                              {
                                invisible:
                                  selectedUtterances.length === 0 ||
                                  editorIsActive,
                              },
                              'transition-colors flex justify-between items-center gap-x-3 rounded-md bg-green-100 py-2 px-3 hover:bg-green-100/80'
                            )}
                          >
                            <span className="font-inter text-xs text-white">
                              Edit from selected clips
                            </span>{' '}
                            <i className="block w-4 h-4 bg-ico-edit-white bg-center bg-no-repeat bg-contain"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 'annotations' && (
                    <div className="block">
                      <AnnotationsTab metadata={props.metadata} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoCard;
