import Root from './root';
import Zeitgeist from './zeitgeist';
import Contact from './contact';
import Notifications from './notifications';
import ClusterDebug from './cluster_debug';
import PrivacyInfo from './privacy';
import Terms from './terms';

export {
  ClusterDebug,
  Contact,
  Notifications,
  Root,
  Zeitgeist,
  PrivacyInfo,
  Terms,
};
