import Cluster, { Run } from '@/interfaces/Cluster';
import * as d3 from 'd3';
import { useEffect, useState } from 'react';

interface ArticleGraph {
  cluster: Cluster;
  run?: Run;
}

interface DataPoint {
  date: Date;
  count: number;
}

const ArticleGraph: React.FC<ArticleGraph> = ({ cluster, run }) => {
  const [chartData] = useState(buildChartData());
  const [xScale, setXScale] = useState<d3.ScaleTime<number, number, never>>();
  const [yScale, setYScale] = useState<d3.ScaleLinear<number, number, never>>();
  const [maxYVal, setMaxYVal] = useState<number>();
  const [ticks, setTicks] = useState<{ value: string; xOffset: number }[]>();

  // Colors
  const axisColor = '#d4d2cd';
  const labelColor = '#2f2f2e';

  // Dimensions
  const width = 240;
  const height = 100;
  const padding = 28;
  const barWidth = 25;

  const labelPadding = 4;
  const yPos = height - padding / 2 + labelPadding;

  const chartId = `chart-${cluster.id}`;

  useEffect(() => {
    const dateFormat = d3.utcFormat('%b %-d');
    const endDate = getStartOfDateUTC(
      chartData[chartData.length - 1].date.getTime() / 1000
    );
    const startDate = getStartOfDateUTC(chartData[0].date.getTime() / 1000);

    const maxYVal = d3.max(chartData, (datapoint) => datapoint.count) || height;
    const xScale = d3
      .scaleTime()
      .domain([startDate, endDate])
      .range([0 + padding, width - padding]);
    const yScale = d3
      .scaleLinear([0, height])
      .domain([0, maxYVal])
      .range([height - padding, 0 + padding / 2]);

    const ticks = xScale.ticks(run?.window).map((value) => ({
      value: dateFormat(value),
      xOffset: xScale(value),
    }));

    ticks.splice(1, ticks.length - 2);

    setXScale(() => xScale);
    setYScale(() => yScale);
    setMaxYVal(maxYVal);
    setTicks(() => ticks);
  }, [chartData]);

  function getStartOfDateUTC(utcTime = 0): Date {
    const newDate = new Date(0);
    newDate.setUTCSeconds(utcTime || 0);
    newDate.setSeconds(0);
    newDate.setMinutes(0);
    newDate.setHours(0);

    return newDate;
  }

  // Find each unique date and count the articles published on that date
  function buildChartData(): DataPoint[] {
    let startingMapData = Array(run?.window).fill(0);
    const oneDay = 60 * 60 * 24;

    // Set date buckets for articles
    startingMapData = startingMapData.map((value: number, index: number) => {
      const date = getStartOfDateUTC(
        run ? run.timestamp - (index + 1) * oneDay : 0
      ).toDateString();

      return [date, value];
    });

    const data = new Map<string, number>(startingMapData);

    // Sort articles into 'day' buckets
    cluster.articles.forEach((article) => {
      const pubDate = getStartOfDateUTC(article.pub_date).toDateString();
      const count = data.get(pubDate);

      if (count !== undefined) {
        data.set(pubDate, count + 1);
      } else {
        data.set(pubDate, 1);
      }
    });

    // Sort and convert to DataPoint

    return Array.from(data)
      .sort((pointA, pointB) =>
        new Date(pointA[0]) > new Date(pointB[0]) ? 1 : -1
      )
      .map((entry) => {
        return { count: entry[1], date: new Date(entry[0]) } as DataPoint;
      })
      .filter((point) => point.date < new Date());
  }

  return (
    <>
      {xScale && yScale && ticks?.length && (
        <svg id={chartId} viewBox={`0 0 ${width} ${height}`}>
          <path
            d={`M ${padding} ${height - padding + 1} H ${width}`}
            stroke={axisColor}
          />
          <path
            d={`M ${padding} ${padding / 2} V ${height - padding}`}
            stroke={axisColor}
          />
          <g fill="#4DA389">
            {chartData.map((datapoint: DataPoint, index: number) => (
              <rect
                key={`${cluster.id}-${index}-rect`}
                x={xScale(datapoint.date)}
                y={yScale(datapoint.count)}
                height={yScale(0) - yScale(datapoint.count)}
                width={barWidth}
              />
            ))}
          </g>
          {ticks.map(({ value, xOffset }) => (
            <g
              className="xAxis"
              key={`${cluster.id}-${value}-x`}
              transform={`translate(${xOffset + 2}, ${yPos})`}
            >
              <text
                color={labelColor}
                key={`${cluster.id}-${value}`}
                style={{
                  textAnchor: 'start',
                }}
              >
                {value}
              </text>
            </g>
          ))}
          <g
            className="yAxis"
            transform={`translate(${padding / 2 - labelPadding}, ${padding / 2 + labelPadding})`}
          >
            <text color={labelColor}>{maxYVal}</text>
          </g>
        </svg>
      )}
    </>
  );
};

export default ArticleGraph;
