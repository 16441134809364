export default {
  ASSET_LIBRARY: {
    MULTICLIP_CARDS: false,
    MULTICLIP_EDITS: false,
    SHOW_ANNOTATIONS_SWITCH: true,
    SHOW_FILTERS_SWITCH: true,
    SORT_BY: true,
    SORT_BY_RELEVANCE: true,
    SEARCH_TRANSCRIPT: true,
    TRENDING: false,
    TRANSCRIPT_HIGHLIGHTS: false,
  },
  NOTIFICATIONS: {
    FILTERS: false,
  },
  CONTACT_US: false,
  TERMS_AND_CONDITIONS: false,
  ZEITGEIST: {
    DEBUG: false,
    ALL_RUNS: false,
  },
};
